.photo-tutorial {
    width:450px;
    height: auto;
}

@media (max-width: 430px) {
    .photo-tutorial {
        width:320px
    }
}
@media (max-width: 375px) {
    .photo-tutorial {
        width:300px
    }
}


@media (max-width: 320px) {
    .photo-tutorial {
        width:250px
    }
}


